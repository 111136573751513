import React from "react";
import PurchaseOrder from "./PurchaseOrder";
import PropTypes from "prop-types";

const PurchaseOrders = (props) => {
  const { deliveryDetail, isComRevisit, isLegacyRevisit } = props;

  return (
    <div id="po-detail-list">
      {deliveryDetail &&
        deliveryDetail.poDetailList &&
        deliveryDetail.poDetailList.map((poDetail, poIndex) => (
          <PurchaseOrder
            poDetail={poDetail}
            woDetail={deliveryDetail.woDetail}
            isComRevisit={isComRevisit}
            isLegacyRevisit={isLegacyRevisit}
            key={poIndex}
          />
        ))}
    </div>
  );
};

PurchaseOrders.propTypes = { deliveryDetail: PropTypes.object.isRequired };

export default PurchaseOrders;
