export function updateCustomerDetail(originalObj) {
  const mapping = {
    custFirstName: "firstName",
    custMiddleName: "middleName",
    custLastName: "lastName",
    custStreetAddress1: "streetAddress1",
    custCity: "city",
    custState: "state",
    custCountry: "country",
    custZipCode: "zipCode",
    emailId: "emailId",
    custPhoneNumber: "dayPhone",
    custAltPhoneNbr: "mobilePhone",
    custOrdId: "customerOrderNumber",
  };

  const transformedObj = {};

  for (const key in mapping) {
    if (Object.prototype.hasOwnProperty.call(originalObj, key)) {
      transformedObj[mapping[key]] = originalObj[key];
    } else {
      transformedObj[mapping[key]] = "";
    }
  }

  return transformedObj;
}

export function updatePurchaseOrderDetail(deliveryDetail) {
  const poDetail = getPoDetailList(deliveryDetail);
  const woDetail = getWoDetail(deliveryDetail);

  if (!woDetail) {
    return poDetail;
  }

  return getPoDetailWithPartsAndServices(poDetail, woDetail);
}

function getPoDetailList(deliveryDetail) {
  const { poDetailList } = deliveryDetail;

  return poDetailList.map((poDetail) => {
    return {
      poNumber: poDetail.poNbr,
      vendorName: poDetail.vendorName,
      vendorNumber: poDetail.vendorNbr,
      msNumber: poDetail.msNbr,
      lineItems: poDetail.lineLevelDetailList
        ? poDetail.lineLevelDetailList
            .map((lineItem) => {
              return {
                skuNumber: lineItem.skuNbr ? lineItem.skuNbr : null,
                modelNumber: lineItem.modelNbr ? lineItem.modelNbr : null,
                lineNumber: lineItem.lineNbr ? lineItem.lineNbr : null,
                quantity: lineItem.quantity ? lineItem.quantity : null,
                itemDesc: lineItem.itemDesc ? lineItem.itemDesc : null,
                serialNumber: lineItem.serialNbr ? lineItem.serialNbr : null,
              };
            })
            .sort((a, b) => a.lineNumber - b.lineNumber)
        : [],
    };
  });
}

function getWoDetail(deliveryDetail) {
  return hasLineLevelDetailList(deliveryDetail)
    ? deliveryDetail.woDetail.lineLevelDetailList.sort(
        (a, b) => parseInt(a.lineNbr) - parseInt(b.lineNbr)
      )
    : null;
}

function hasLineLevelDetailList(deliveryDetail) {
  return (
    deliveryDetail &&
    deliveryDetail.woDetail &&
    deliveryDetail.woDetail.lineLevelDetailList &&
    deliveryDetail.woDetail.lineLevelDetailList.length > 0
  );
}

function getPoDetailWithPartsAndServices(poDetail, woDetail) {
  const lineNumberOrder = getOrderOfLineItems(poDetail);

  return poDetail.map((orderDetail) => {
    const lineItemsWithPartsAndServices = orderDetail.lineItems.map(
      (lineItem) => {
        const nextLineNumber = lineNumberOrder.find(
          (element) => element > parseInt(lineItem.lineNumber)
        );

        const associatedLineLevelDetails = woDetail.filter((detail) => {
          const detailLineNumber = parseInt(detail.lineNbr);
          const currentLineNumber = parseInt(lineItem.lineNumber);

          return nextLineNumber
            ? detailLineNumber > currentLineNumber &&
                detailLineNumber < nextLineNumber
            : detailLineNumber > currentLineNumber;
        });

        const partsAndServices = associatedLineLevelDetails.map(
          (matchingDetail) => {
            return {
              skuNumber: matchingDetail.skuNbr,
              modelNumber: matchingDetail.modelNbr,
              lineNumber: matchingDetail.lineNbr,
              quantity: matchingDetail.quantity,
              itemDesc: matchingDetail.itemDesc,
            };
          }
        );

        return {
          ...lineItem,
          partsAndServices: partsAndServices,
        };
      }
    );

    return {
      ...orderDetail,
      lineItems: lineItemsWithPartsAndServices,
    };
  });
}

function getOrderOfLineItems(poDetail) {
  return poDetail
    .flatMap((orderDetail) =>
      orderDetail.lineItems.map((lineItem) => parseInt(lineItem.lineNumber))
    )
    .sort((a, b) => a - b);
}
