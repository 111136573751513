import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./Location.less";
import LocationContext from "../../context/LocationContext";
import { Select, Menu } from "antd";
import { useSearchStateHook } from "../../hooks/useSearchStateHook";
import { sanitizer } from "../Search/search.helper";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import * as newRelicConstants from "../../util/NewRelicConstants";
const Location = (props) => {
  const { locations } = props;
  const { setCustomAttribute } = useNewRelicMetrics();
  const { currentLocation, setCurrentLocation } = useContext(LocationContext);
  const { Option } = Select;
  const { resetFilters } = useSearchStateHook(
    {
      type: "",
      input: "",
      optionalInput: "",
      date: "",
    },
    { sanitizer }
  );
  const menu = (
    <div id="list-container">
      <Menu
        className="location-dropdown"
        id="dropdown-menu"
        onClick={({ key }) => {
          setCurrentLocation(locations[key]);
        }}
      >
        {locations.map((location, i) => (
          <Menu.Item key={i}>{location.locationName}</Menu.Item>
        ))}
      </Menu>
    </div>
  );

  function handleOnLocationChange(value) {
    //Change location custom Attribute
    setCustomAttribute(
      newRelicConstants.ATTR_NAMES.user_location,
      locations[value].locationName
    );
    resetFilters();
    setCurrentLocation(locations[value]);
  }

  return (
    <div className="location-dropdown-container">
      <span className="location-text">Location</span>
      {locations.length === 1 ? (
        <span id="location-value">{currentLocation.locationName}</span>
      ) : (
        <div className="select-wrapper">
          <Select
            defaultValue={currentLocation.locationName}
            showSearch
            bordered={false}
            placeholder="Select a Location"
            style={{ width: 200 }}
            optionFilterProp="children"
            dropdownStyle={{ background: "#444444" }}
            dropdownRender={(menu) => (
              <div className="dropdown-wrapper">{menu}</div>
            )}
            onChange={handleOnLocationChange}
            onSearch={(value, e) => {
              resetFilters();
              setCurrentLocation(
                e && e.preventDefault ? locations[value] : currentLocation
              );
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {locations.map((location, i) => (
              <Option className="option-container" key={i}>
                {location.locationName}
              </Option>
            ))}
          </Select>
        </div>
      )}
    </div>
  );
};

Location.propTypes = { locations: PropTypes.array.isRequired };

export default Location;
