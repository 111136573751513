import React, { useContext, useState } from "react";
import OrderContext from "../../../context/OrderContext";
import { Dropdown, Button, Menu, Tooltip } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { userRole } from "../../../util/Roles";
import { UserContext } from "../../../context/RootContext";
import "./ManualOrderButton.less";
import { createRevisitReasons } from "../../../util/Roles";
import * as constants from "../../../util/Constants";
import useNewRelicMetrics from "../../../hooks/newRelicMetricsHook";
import {
  OVERLAY_TYPE_REVISIT_COMMONS,
  OVERLAY_TYPE_REVISIT_LEGACY,
  REVISIT_CLICK_EVENT,
} from "../../../util/NewRelicConstants";
import { RevisitToolTip } from "../RevisitToolTip/RevisitToolTip";
import { REVISIT_REPLACEMENT_SERVICE_CODE } from "../../../util/Constants";

const ManualOrderButton = (props) => {
  const {
    isComRevisit,
    orderIndex,
    orderNumber,
    location,
    revisitLevelOfService,
  } = props;
  const { setRevisitDrawerOpen, toggleManualOrder, setRevisitType } =
    useContext(OrderContext);
  const [outlinedArrowDirection, setOutlinedArrowDirection] = useState(true);
  const { user } = useContext(UserContext);
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  function shouldDisplayRevisitDrawer(revisitType) {
    return (
      process.env.REACT_APP_REVISIT_ENABLED === "Y" &&
      process.env.REACT_APP_REVISIT_LOCATIONS.split(",").includes(location) &&
      (revisitType === constants.REVISIT_DA_SERVICE_CALL ||
        revisitType === constants.REVISIT_REPLACEMENT)
    );
  }

  function handleClick(revisitType) {
    if (shouldDisplayRevisitDrawer(revisitType)) {
      setRevisitType(revisitType);
      setOutlinedArrowDirection(!outlinedArrowDirection);
      setRevisitDrawerOpen(true);
      logNewRelicMetricsEvent(REVISIT_CLICK_EVENT, {
        order_number: orderNumber,
        index: orderIndex,
        text: revisitType,
        overlay: OVERLAY_TYPE_REVISIT_COMMONS,
      });
    } else {
      setRevisitType(revisitType);
      toggleManualOrder(true);
      logNewRelicMetricsEvent(REVISIT_CLICK_EVENT, {
        order_number: orderNumber,
        index: orderIndex,
        text: revisitType,
        overlay: OVERLAY_TYPE_REVISIT_LEGACY,
      });
    }
  }

  const handleVisibleChange = (flag) => {
    if (flag === false) {
      setOutlinedArrowDirection(!outlinedArrowDirection);
    }
  };

  const role = userRole(user);
  const reasonsForRevisit = createRevisitReasons(role);
  const isReplacementRevisit =
    revisitLevelOfService === REVISIT_REPLACEMENT_SERVICE_CODE;

  const menu = (
    <Menu
      onSelect={({ key }) => {
        /* Do nothing */
      }}
    >
      {reasonsForRevisit.map((item) => (
        <Menu.Item
          className="dropdown-item"
          onClick={({ key }) => {
            handleClick(key);
          }}
          key={item.key}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return isComRevisit && !isReplacementRevisit ? (
    <RevisitToolTip buttonName={"Create Revisit"} isUpOutline={true} />
  ) : (
    <div>
      <div id="create-revisit-dropDown">
        <Dropdown
          onVisibleChange={handleVisibleChange}
          style={{ color: "#f96302" }}
          className="default-btn"
          overlay={menu}
          trigger={["click"]}
        >
          <Button
            className="revisit-button"
            onClick={() => {
              setOutlinedArrowDirection(!outlinedArrowDirection);
            }}
          >
            <div className="button-content">
              <span />
              <span>Create Revisit</span>
              <span>
                {outlinedArrowDirection ? <UpOutlined /> : <DownOutlined />}
              </span>
            </div>
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default ManualOrderButton;
