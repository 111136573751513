import { useContext, useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import LocationContext from "../../context/LocationContext";
import axios from "axios";
import { API_CONFIG } from "../../util/RoutePlanner/routePlannerUtils";
import getDrdsBffUrl from "../../util/RoutePlanner/getDrdsBffUrl";
import { formatTo12Hour } from "../../util/DateFormatter";
import moment from "moment";

import {
  identifyTab,
  queryStringBuilder,
  checkPageType,
} from "./useContextManager";

const useRoutePlannerData = () => {
  const routerLocation = useLocation();
  const locationContext = useContext(LocationContext);
  const previousQuery = useRef({});

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [enableDownloadManifest, setEnableDownloadManifest] = useState(false);

  useEffect(() => {
    setData([]);
  }, [locationContext.currentLocation]);

  useEffect(() => {
    if (checkPageType(routerLocation) === "Summary") {
      const contextParams = queryStringDecoder(routerLocation);
      const tab = identifyTab(routerLocation);

      if (tab === "dispatch") {
        contextParams.date = moment().format("YYYY-MM-DD");
      }

      if (
        isQueryValid(tab, contextParams) &&
        isQueryChanged(
          locationContext.currentLocation,
          tab,
          contextParams,
          previousQuery
        )
      ) {
        const apiQuery = apiQueryBuilder(
          tab,
          convertToQueryParams(contextParams),
          locationContext.currentLocation
        );
        fetch(tab, apiQuery);
      }
    }
  }, [routerLocation]);

  async function fetch(tab, apiQuery) {
    setData([]);
    setLoading(true);
    try {
      const drdsApiResponse = await axios.get(
        `${getDrdsBffUrl()}${API_CONFIG.BASE_URL}${apiQuery}`
      );
      if (drdsApiResponse.data && drdsApiResponse.data.length !== 0) {
        setEnableDownloadManifest(true);
      }
      switch (tab) {
        case "unassigned":
          setData(parseUnassignedData(drdsApiResponse.data));
          break;
        case "archive":
          setData(parseArchiveData(drdsApiResponse.data));
          break;
        case "dispatch":
          setData(parseDispatchSummaryData(drdsApiResponse.data));
          break;
        case "planning":
          setData(parsePlanningData(drdsApiResponse.data));
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setEnableDownloadManifest(false);
      setError(error.message);
      setData([]);
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    loading,
    error,
    enableDownloadManifest,
  };
};

export function convertToQueryParams(contextParams) {
  if (contextParams.searchType && contextParams.searchTerm) {
    return {
      date: contextParams.date,
      [contextParams.searchType]: contextParams.searchTerm,
    };
  }
  return contextParams;
}

export function queryStringDecoder(routerLocation) {
  const queryDict = {};
  const entries = routerLocation.search.slice(1).split("&");
  entries.forEach((entry) => {
    const [key, value] = entry.split("=");
    if (key === "date") {
      queryDict.date = value;
    }
    if (
      key === "customerOrderNumber" ||
      key === "customerLastName" ||
      key === "msNumber"
    ) {
      queryDict.searchType = key;
      queryDict.searchTerm = value;
    }
  });
  return queryDict;
}

export function isQueryChanged(location, tab, queryParams, previousQuery) {
  const changed =
    previousQuery.current.location !== location ||
    previousQuery.current.tab !== tab ||
    previousQuery.current.date !== queryParams.date ||
    previousQuery.current.searchType !== queryParams.searchType ||
    previousQuery.current.searchTerm !== queryParams.searchTerm;
  if (changed) {
    previousQuery.current = { location, tab, ...queryParams };
  }
  return changed;
}

export function isQueryValid(tab, queryParams) {
  switch (tab) {
    case "archive":
      return (
        queryParams.date !== undefined ||
        (queryParams.searchType !== undefined &&
          queryParams.searchTerm !== undefined)
      );
    case "unassigned":
    case "dispatch":
    case "planning":
      return queryParams.date !== undefined;
    default:
      return false;
  }
}

export function apiQueryBuilder(tab, queryParams, currentLocation) {
  switch (tab) {
    case "archive":
      return `/archive?location=${
        currentLocation.locationNumber
      }&${queryStringBuilder(queryParams)}`;
    case "unassigned":
      return `/unassigned?location=${
        currentLocation.locationNumber
      }&${queryStringBuilder(queryParams)}`;
    case "dispatch":
    case "planning":
      return `/summary?tab=${tab}&location=${
        currentLocation.locationNumber
      }&${queryStringBuilder(queryParams)}`;
    default:
      return "";
  }
}

function parsePlanningData(drdsApiResponse) {
  const dataSource = [];
  if (drdsApiResponse != null) {
    drdsApiResponse.forEach((dispatchSummary, idx) => {
      dataSource.push({
        key: idx,
        carrierName: dispatchSummary.carrierName,
        driverName: dispatchSummary.driverName,
        lastUpdatedTimestamp: dispatchSummary.lastUpdatedTimestamp,
        numberOfStops: dispatchSummary.numberOfStops,
        routeId: dispatchSummary.routeId,
        truckId: dispatchSummary.truckId,
      });
    });
  }
  return dataSource;
}

function parseUnassignedData(drdsApiResponse) {
  const dataSource = [];
  if (drdsApiResponse != null) {
    drdsApiResponse.forEach((unassignedStop, idx) => {
      dataSource.push({
        key: idx,
        orderNumber: unassignedStop.orderNumber,
        workOrderNumber: unassignedStop.workOrderNumber,
        msNumber: unassignedStop.msNumber,
        customerName: unassignedStop.customerName,
        address: unassignedStop.address,
        serviceType: unassignedStop.serviceType,
        deliveryWindow: unassignedStop.deliveryWindow,
      });
    });
  }
  return dataSource;
}

function parseArchiveData(archiveResponse) {
  const dataSource = [];
  let inc = 0;
  if (archiveResponse != null) {
    archiveResponse.forEach((archiveArray) => {
      archiveArray.stops.forEach((allStops, idx) => {
        dataSource.push({
          key: `${inc}${idx}`,
          routeId: archiveArray.routeId,
          driverName: archiveArray.driverName,
          orderNumber: allStops.orderNumber,
          workOrderNumber: allStops.workOrderNumber,
          msn: allStops.msNumber,
          customerName: allStops.customerName,
          serviceType: allStops.serviceType,
          deliveryDate:
            allStops.actualArrivalDateTime &&
            allStops.actualArrivalDateTime.split(" ")[0],
          actualArrivalDateTime: allStops.actualArrivalDateTime,
          timeAtStop: allStops.timeAtStop,
          deliveryStatus: allStops.deliveryStatus,
          stopId: allStops.stopId,
          deliveryWindow: allStops.deliveryWindow,
        });
      });
      inc++;
    });
  }
  return dataSource;
}

function parseDispatchSummaryData(drdsApiResponse) {
  const dataSource = [];
  if (drdsApiResponse != null) {
    drdsApiResponse.forEach((dispatchSummary, idx) => {
      dataSource.push({
        key: idx,
        truckId: dispatchSummary.truckId,
        routeId: dispatchSummary.routeId,
        driverName: dispatchSummary.driverName,
        carrierName: dispatchSummary.carrierName,
        numberOfStops: dispatchSummary.numberOfStops,
        completionEta: dispatchSummary.completionEta,
      });
    });
  }
  return dataSource;
}

function getArrivalTime(estArrivalDateTime) {
  return formatTo12Hour(estArrivalDateTime.split(" ")[1]);
}

export default useRoutePlannerData;
