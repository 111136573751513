import React from "react";
import "./PurchaseOrder.less";
import PropTypes from "prop-types";

const CancelledProductLine = (props) => {
  const { productLine, isLegacyRevisit } = props;

  return (
    <div id="cancelled-product-line-content">
      {!isLegacyRevisit && (
        <>
          <div className="cancelled-description-label">Description</div>
          <div className="cancelled-description">
            {productLine.skuDesc ? productLine.skuDesc : "Not Available"}
          </div>
        </>
      )}
      <div
        className={`cancelled-model-number-label${
          isLegacyRevisit ? "" : " label-offset"
        }`}
      >
        Model #
      </div>
      <div className="cancelled-model-number">{productLine.modelNbr}</div>
      <div className="cancelled-sku-number-label">Store SO SKU</div>
      <div className="cancelled-sku-number">{productLine.skuNbr}</div>
    </div>
  );
};

CancelledProductLine.propTypes = { productLine: PropTypes.object.isRequired };

export default CancelledProductLine;
