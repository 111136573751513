import React from "react";
import "./PurchaseOrder.less";
import PropTypes from "prop-types";

const ProductLine = (props) => {
  const { productLine, isLegacyRevisit } = props;
  const displayCancellation = getCancellationString();
  function getCancellationString() {
    if (productLine && productLine.quantity < 1) {
      return <div className="line-cancelled-label">CANCELLED</div>;
    }
  }

  return (
    <div id="product-line">
      {!isLegacyRevisit && (
        <>
          <div className="description-label">Description</div>
          <div className="description">
            {productLine.skuDesc ? productLine.skuDesc : "Not Available"}
          </div>
        </>
      )}
      <div
        className={`model-number-label${
          isLegacyRevisit ? "" : " label-offset"
        }`}
      >
        Model #
      </div>
      <div className="model-number">{productLine.modelNbr}</div>
      <div className="sku-number-label">Store SO SKU</div>
      <div className="sku-number">{productLine.skuNbr}</div>
      {displayCancellation}
    </div>
  );
};

ProductLine.propTypes = { productLine: PropTypes.object.isRequired };

export default ProductLine;
