import { useContext, useState } from "react";
import axios from "axios";
import moment from "moment";
import getBffUrl from "../util/getBffUrl";
import * as constants from "../util/Constants";
import { DASHBOARD_STH_CONTEXT } from "../util/Constants";
import permit, { checkAdditionalRoleAccessForUser } from "../util/Permissions";
import DeliveriesCommonContext from "../context/DeliveriesCommonContext";
import { UserContext } from "../context/RootContext";
import LocationContext from "../context/LocationContext";
import DashboardContext from "../context/DashboardContext";
import SearchContext from "../context/SearchContext";

export default function useSearchResult() {
  const { currentLocation } = useContext(LocationContext);
  const { user } = useContext(UserContext);
  const dashboardContext = useContext(DashboardContext);
  const { setInitSearchResult, setSearchResult } = useContext(
    DeliveriesCommonContext
  );
  const [previousSearchParams, setPreviousSearchParams] = useState({});

  const getInitSearchResult = async (
    paginationParams,
    loading,
    setLoading,
    values,
    filters
  ) => {
    const { currentPage: from, pageSize: size } = paginationParams;
    const searchParams =
      dashboardContext === DASHBOARD_STH_CONTEXT
        ? getSTHSearchParams(values)
        : getSearchParams(values, filters);

    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(getSearchEndpoint(), {
        params: { from, size, ...searchParams },
      });

      if (response && response.status === 200) {
        setInitSearchResult(response.data);
        setSearchResult(response.data);
      } else {
        setInitSearchResult(null);
        setSearchResult(null);
      }
      return response;
    } catch (error) {
      setInitSearchResult((prevState) => {
        if (
          prevState &&
          prevState.total &&
          JSON.stringify(searchParams) === JSON.stringify(previousSearchParams)
        ) {
          return {
            total: prevState.total,
          };
        }
        return null;
      });
      setSearchResult((prevState) => {
        if (
          prevState &&
          prevState.total &&
          JSON.stringify(searchParams) === JSON.stringify(previousSearchParams)
        ) {
          return {
            total: prevState.total,
          };
        }
        return null;
      });
      return {
        error,
        data: null,
      };
    } finally {
      setLoading(false);
      setPreviousSearchParams(searchParams);
    }
  };

  const getSearchResult = async (paginationParams, setLoading, values) => {
    const { currentPage: from, pageSize: size } = paginationParams;
    const searchParams =
      dashboardContext === DASHBOARD_STH_CONTEXT
        ? getSTHSearchParams(values)
        : getSearchParams(values);
    setLoading(true);

    try {
      const response = await axios.get(getSearchEndpoint(), {
        params: { from, size, ...searchParams },
      });
      if (response && response.status === 200) {
        setSearchResult(response.data);
      } else {
        setInitSearchResult(null);
        setSearchResult(null);
      }

      return response;
    } catch (error) {
      setInitSearchResult((prevState) => {
        if (
          prevState &&
          prevState.total &&
          JSON.stringify(searchParams) === JSON.stringify(previousSearchParams)
        ) {
          return {
            total: prevState.total,
          };
        }
        return null;
      });
      setSearchResult((prevState) => {
        if (
          prevState &&
          prevState.total &&
          JSON.stringify(searchParams) === JSON.stringify(previousSearchParams)
        ) {
          return {
            total: prevState.total,
          };
        }
        return null;
      });
      return {
        error,
        data: null,
      };
    } finally {
      setLoading(false);
      setPreviousSearchParams(searchParams);
    }
  };

  const getFilteredSearchResult = async (
    paginationParams,
    filters,
    setLoading,
    values
  ) => {
    const { currentPage: from, pageSize: size } = paginationParams;
    const searchParams =
      dashboardContext === DASHBOARD_STH_CONTEXT
        ? // @todo handle STH to take values
          getSTHSearchParams(values)
        : getSearchParams(values, filters);
    setLoading(true);

    try {
      const response = await axios.get(getSearchEndpoint(), {
        params: { from, size, ...searchParams },
      });

      if (response && response.status === 200) {
        setSearchResult(response.data);
      } else {
        console.log("Error accessing the dashboard data");
        setSearchResult(null);
      }

      return response;
    } catch (error) {
      setSearchResult((prevState) => {
        if (
          prevState &&
          prevState.total &&
          JSON.stringify(searchParams) === JSON.stringify(previousSearchParams)
        ) {
          return {
            total: prevState.total,
          };
        }
        return null;
      });
      return {
        error,
        data: null,
      };
    } finally {
      setLoading(false);
      setPreviousSearchParams(searchParams);
    }
  };

  function getSearchParams(values, filters) {
    const params = {};
    if (values && values.input && values.type) {
      if (values.type === "CustomerName") {
        params["CustomerLastName"] = values.input;
        if (values.optionalInput && values.optionalInput.length !== 0) {
          params["CustomerFirstName"] = values.optionalInput;
        }
      } else if (values.type === "PONumber") {
        params["PONumber"] = values.input;
        if (values.optionalInput && values.optionalInput.length !== 0) {
          params["StoreNumber"] = values.optionalInput;
        }
      } else {
        params[values.type] = values.input;
      }
    } else if (values.date) {
      params.DeliveryDate = values.date;
    } else {
      params.DeliveryDate = moment().format("MM/DD/YYYY");
    }
    if (
      currentLocation &&
      currentLocation.locationNumber &&
      values.type !== "OrderNumber" &&
      values.type !== "MSNumber" &&
      (permit("locationBasedSearch", user) || values.type === "DeliveryDate")
    ) {
      params.LocationNumber = currentLocation.locationNumber;
    }

    if (
      process.env.REACT_APP_DMP_ALWAYS_SEARCH_APPLIANCE === "Y" ||
      isApplianceSearchEnabledForCurrentLocation() ||
      checkAdditionalRoleAccessForUser(
        constants.DMP_DASHBOARD_COMMON_ACCESS_GROUP,
        user
      )
    ) {
      params["searchAppliances"] = true;
    }

    if (filters) {
      if (filters.deliveryDate) {
        if (`${filters.deliveryDate}` === "No Delivery Date") {
          params["DeliveryDateFilter"] = `${filters.deliveryDate}`;
        } else {
          params[
            "DeliveryDateFilter"
          ] = `${filters.deliveryDate}-${filters.deliveryDate}`;
        }
      }

      if (filters.workOrderStatus) {
        params["DeliveryStatusFilter"] = [...filters.workOrderStatus].join(",");
      }

      if (filters.deliveryLocation) {
        params["DeliveryLocationFilter"] = [...filters.deliveryLocation].join(
          ","
        );
      }

      if (filters.isAppliance) {
        params["OrderTypeFilter"] = [...filters.isAppliance].join(",");
      }
    }

    return params;
  }

  function getSTHSearchParams(values) {
    const params = {};
    if (values.type === "WONumber") {
      params["WorkOrderNumber"] = values.input;
    } else {
      params[values.type] = values.input;
    }
    return params;
  }

  function getSearchEndpoint() {
    const baseEndpoint =
      dashboardContext === DASHBOARD_STH_CONTEXT
        ? "/v1/sth/search/deliveries"
        : "/v1/search/deliveries";
    return `${getBffUrl()}${baseEndpoint}`;
  }

  function isApplianceSearchEnabledForCurrentLocation() {
    return (
      process.env.REACT_APP_DMP_SEARCH_APPLIANCE_LOCATIONS &&
      process.env.REACT_APP_DMP_SEARCH_APPLIANCE_LOCATIONS.split(",").includes(
        currentLocation.locationNumber.toString()
      )
    );
  }

  return { getInitSearchResult, getSearchResult, getFilteredSearchResult };
}
