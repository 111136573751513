import React from "react";
import "./TimeWindow.less";
import { formatTo12Hour } from "../../../../util/DateFormatter";
import * as constants from "../../../../util/Constants";

function TimeWindow({ deliveryDetail, timeWindowString }) {
  //check context and use this function if not sth context.

  const { deliveryStartDateTime, deliveryEndDateTime } = deliveryDetail;

  const [, deliveryStartTime] = deliveryStartDateTime.split("T");

  const [, deliveryEndTime] = deliveryEndDateTime.split("T");
  return (
    <div className="time-window-card">
      <div className="delivery-status">{timeWindowString}</div>
      <div className="time-window">
        {formatTo12Hour(deliveryStartTime)} - {formatTo12Hour(deliveryEndTime)}
      </div>
      {/* <div className="time-window-btns-wrapper">
        <Button size="small" type="link">
          Modify
        </Button>

        <Button size="small" type="link">
          Reset
        </Button>
      </div> */}
    </div>
  );
}

export default TimeWindow;
