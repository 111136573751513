import {
  DASHBOARD_COMMON_CONTEXT,
  DASHBOARD_CONTEXT,
  DASHBOARD_ROUTE_PLANNER_CONTEXT,
  DASHBOARD_STH_CONTEXT,
} from "./Constants";

export const HEADER_NAV_BUTTON_EVENT = "click.dmp.header.button.navigate";
export const ORDER_CARD_EXPAND_EVENT = "click.dmp.?.order_row.expand_row";
export const WORK_BIN_ORDER_CARD_EXPAND_EVENT =
  "click.dmp.workbin.order_row.expand_row";
export const SEARCH_CALENDAR_EVENT = "click.dmp.?.search.calendar";
export const SEARCH_EVENT = "click.dmp.?.search";
export const SEARCH_RESULTS_EVENT = "load.dmp.?.search.results";
export const FILER_EVENT = "click.dmp.?.search.filter";
export const SORT_EVENT = "click.dmp.?.search.sort";
export const SEARCH_CLEAR_EVENT = "click.dmp.?.search.clear";
export const PAGINATION_CHANGE_PAGE_EVENT = "click.dmp.?.paginaton.change_page";

export const NEW_MANUAL_ORDER_CLICK_EVENT =
  "click.dmp.dashboard.button.new_manual_order";
export const NEW_MANUAL_ORDER_CONTINUE_EVENT =
  "click.dmp.dashboard.button.new_manual_order_continue";
export const NEW_MANUAL_ORDER_NEXT_EVENT =
  "click.dmp.dashboard.overlay.new_manual_order_next";
export const NEW_MANUAL_ORDER_NEXT_ERROR_EVENT =
  "error.dmp.dashboard.overlay.new_manual_order_next";
export const NEW_MANUAL_ORDER_CANCEL_EVENT =
  "click.dmp.dashboard.overlay.new_manual_order_cancel";
export const NEW_MANUAL_ORDER_SUBMIT_EVENT =
  "submit.dmp.dashboard.overlay.new_manual_order_submit";
export const NEW_MANUAL_ORDER_SUBMIT_ERROR_EVENT =
  "error.dmp.dashboard.overlay.new_manual_order_submit";

export const REVISIT_NEXT_EVENT = "click.dmp.?.order_row.create_revisit_next";
export const REVISIT_CANCEL_EVENT =
  "click.dmp.?.order_row.create_revisit_cancel";
export const REVISIT_SUBMIT_EVENT = "submit.dmp.?.order_row.create_revisit";
export const REVISIT_CLICK_EVENT = "click.dmp.?.order_row.create_revisit";
export const REVISIT_SUBMIT_ERROR = "error.dmp.?.order_row.create_revisit";
export const CUSTOMER_INFO_SAVE_CHANGES_EVENT =
  "click.dmp.dashboard.customer_info.save_changes";
export const LEGACY_RESCHEDULE_EVENT = "click.dmp.dashboard.legacy.reschedule";
export const ORDER_ITEM_DELIVERY_TRACKER = "click.dmp.?.order_row.navigate";
export const ALL_WORK_ORDERS_EVENT = "click.dmp.?.order_row.all_work_orders";
export const WORK_BIN_ALL_WORK_ORDERS_EVENT =
  "click.dmp.workbin.order_row.all_work_orders";
export const RESCHEDULE_EVENT = "click.dmp.?.order_row.reschedule";
export const CUSTOMER_INFO_EVENT = "click.dmp.?.order_row.customer_info";
export const WORK_BIN_RESCHEDULE_EVENT =
  "click.dmp.workbin.order_row.reschedule";
export const ADD_NOTE_EVENT = "click.dmp.?.order_row.add_note";
export const ADD_NOTE_CANCEL_EVENT = "click.dmp.?.order_row.add_note_cancel";
export const SUBMIT_ADD_NOTE_EVENT = "submit.dmp.?.order_row.add_note";
export const VIEW_ALL_NOTE_EVENT = "click.dmp.?.order_row.view_all_note";
export const VIEW_ALL_NOTE_TAB_EVENT =
  "click.dmp.?.order_row.view_all_note_tab";
export const PRINT_EVENT = "click.dmp.?.order_row.print";
export const CANCEL_DELIVERY_EVENT = "click.dmp.?.order_row.cancel_delivery";
export const CANCEL_DELIVERY_SUBMIT_EVENT =
  "submit.dmp.?.order_row.cancel_delivery";
export const CONFORMATION_POP_UP_REVISIT_EVENT =
  "click.dmp.?.order_row.conformation_pop_up_revisit";
export const CANCEL_RESCHEDULE_MODAL_EVENT =
  "click.dmp.?.order_row.cancel_reschedule_modal";
export const CANCEL_CUSTOMER_INFO_MODAL_EVENT =
  "click.dmp.?.order_row.cancel_customer_info_modal";
export const CLOSE_RESCHEDULE_MODAL_EVENT =
  "click.dmp.?.order_row.close_reschedule_modal";
export const CLOSE_CUSTOMER_INFO_MODAL_EVENT =
  "click.dmp.?.order_row.close_customer_info_modal";
export const VIEW_POD_EVENT = "click.dmp.?.order_row.view_pod";
export const ERROR_LOGOUT_EVENT = "error.dmp.?.forced_logout";
export const DASHBOARD_SEARCH = {
  [DASHBOARD_STH_CONTEXT]: "dashboard-sth",
  [DASHBOARD_COMMON_CONTEXT]: "dashboard-beta",
  [DASHBOARD_CONTEXT]: "dashboard",
  [DASHBOARD_ROUTE_PLANNER_CONTEXT]: "route-planner",
};
export const HEADER_NAV_MENU = {
  "/": "Dashboard",
  "/dca": "DCA",
  "/dashboard-sth": "Dashboard STH",
  "/dashboard-common": "Dashboard Beta",
  "/workbin": "Workbin",
  "/mail": "Mail",
  "/route-planner/routes": "Route Planner View",
  mars: "Mars",
  emt: "EMT",
};

export const ATTR_NAMES = {
  user_company: "user.company",
  user_role: "user.role",
  user_location: "user.location",
  app_version: "app.version",
};
export const OVERLAY_TYPE_REVISIT_COMMONS = "COMMONS";
export const OVERLAY_TYPE_REVISIT_LEGACY = "LEGACY";
