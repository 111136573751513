import { Link } from "react-router-dom";
import moment from "moment/moment";
import { convertDateFormat, formatTo12HourRP } from "../DateFormatter";
import TimeStatusComponent from "../../components/RoutePlannerView/common/TimeStatusComponent";

export const filterOptions = {
  generateDefaultOptions: (data, filterName) => {
    const uniqueValues = [
      ...new Set(data.map((item) => item[filterName])),
    ].filter((value) => value !== null && value !== undefined && value !== ""); // Filter out empty, null, or undefined values
    return uniqueValues.map((value) => ({ text: value, value }));
  },
  generateDeliveryWindowOptions: (data) => {
    const uniqueValues = [
      ...new Set(
        data.map((item) => {
          const { deliveryWindow } = item;
          if (
            deliveryWindow &&
            deliveryWindow.earliestArrivalDateTime &&
            deliveryWindow.latestArrivalDateTime
          ) {
            const earliestDate = formatTime(
              deliveryWindow.earliestArrivalDateTime
            );
            const latestDate = formatTime(deliveryWindow.latestArrivalDateTime);
            return `${earliestDate} - ${latestDate}`;
          }
          return null;
        })
      ),
    ].filter(Boolean);

    return uniqueValues.map((value) => ({
      text: value,
      value,
    }));
  },
};

export const columnRenderers = {
  actions: (column, { url, queryParams }) => ({
    ...column,
    render: (_, record) => {
      const path = `${url}/details/${record.routeId}`;
      return (
        <Link
          to={{
            pathname: path,
            search: null,
          }}
          state={{
            fromDashboard: true,
            queryParams,
          }}
        >
          {" "}
          View Details
        </Link>
      );
    },
  }),
  deliveryWindow: (column, columnConfig) => ({
    ...getDefaultColumnConfig(column, columnConfig),
    render: (deliveryWindow) => {
      if (
        deliveryWindow &&
        deliveryWindow.earliestArrivalDateTime &&
        deliveryWindow.latestArrivalDateTime
      ) {
        const earliestDate = formatTime(deliveryWindow.earliestArrivalDateTime);
        const latestDate = formatTime(deliveryWindow.latestArrivalDateTime);
        return `${earliestDate} - ${latestDate}`;
      }
      return null;
    },
    onFilter: (value, record) => {
      const { deliveryWindow } = record;
      if (
        deliveryWindow &&
        deliveryWindow.earliestArrivalDateTime &&
        deliveryWindow.latestArrivalDateTime
      ) {
        const earliestDate = formatTime(deliveryWindow.earliestArrivalDateTime);
        const latestDate = formatTime(deliveryWindow.latestArrivalDateTime);
        const combinedValue = `${earliestDate} - ${latestDate}`;
        return combinedValue.indexOf(value) === 0;
      }
      return false;
    },
    sorter: (a, b) => {
      return formatDeliveryTime(
        a[column.key].earliestArrivalDateTime,
        a[column.key].latestArrivalDateTime
      ).localeCompare(
        formatDeliveryTime(
          b[column.key].earliestArrivalDateTime,
          b[column.key].latestArrivalDateTime
        )
      );
    },
  }),
  driverNotes: (
    column,
    {
      setIsDrawerOpen,
      setSelectedStopId,
      setDriverNotesOrderNumber,
      setDriverNotesWorkOrderNumber,
    }
  ) => ({
    ...column,
    render: (record) => {
      return (
        <img
          src="/images/Note_icon.svg"
          alt={"DriverNote"}
          data-testid="driver-notes"
          className="driver-notes-icon"
        />
      );
    },
    onCell: (record) => {
      return {
        onClick: () => {
          setIsDrawerOpen(true);
          setSelectedStopId(record.stopId);
          setDriverNotesOrderNumber(record.orderNumber);
          setDriverNotesWorkOrderNumber(record.workOrderNumber);
        },
      };
    },
  }),
  lastUpdatedTimestamp: (column, columnConfig) => ({
    ...getDefaultColumnConfig(column, columnConfig),
    render: (lastUpdatedTimestamp) => {
      if (lastUpdatedTimestamp) {
        return formatTimestamps(lastUpdatedTimestamp);
      }
      return null;
    },
  }),
  /**
   * Field is named with minutes, but the value is actually seconds; we are converting the time based on seconds.
   */
  timeAtStop: (column, { timeAtStop }) => ({
    ...column,
    render: (timeAtStop) => {
      if (timeAtStop !== null && timeAtStop !== undefined) {
        if (timeAtStop >= 3600) {
          const hours = Math.floor(timeAtStop / 3600);
          const remainingTimes = timeAtStop % 3600;
          const minutes = Math.floor(remainingTimes / 60);
          return `${hours} h ${minutes} m`;
        } else if (timeAtStop < 60) {
          return `${timeAtStop} s`;
        } else {
          const minutes = Math.floor(timeAtStop / 60);
          const seconds = timeAtStop % 60;
          return `${minutes} m ${seconds} s`;
        }
      }
    },
  }),
  completionEta: (column, columnConfig) => ({
    ...getDefaultColumnConfig(column, columnConfig),
    render: (completionEta) => {
      return completionEta
        ? formatTo12HourRP(completionEta.split(" ")[1])
        : null;
    },
  }),
  estArrivalDateTime: (column, columnConfig) => ({
    ...getDefaultColumnConfig(column, columnConfig),
    render: (estArrivalDateTime, record) => {
      return (
        <TimeStatusComponent
          time={estArrivalDateTime}
          deliveryWindow={record.deliveryWindow}
        />
      );
    },
  }),
  actualArrivalDateTime: (column, columnConfig) => ({
    ...getDefaultColumnConfig(column, columnConfig),
    render: (actualArrivalDateTime, record) => {
      return column.title === "Arrival Time" ? (
        <TimeStatusComponent
          time={actualArrivalDateTime}
          deliveryWindow={record.deliveryWindow}
        />
      ) : (
        formatDateTo12hTime(actualArrivalDateTime)
      );
    },
  }),
};

/**
 * Locale-aware string comparer for consistent sorting.
 * - Uses default locale
 * - Sorts numbers correctly (e.g., '2' < '10')
 * - Ignores case and accents
 * @type {Intl.Collator}
 */
const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

// Default sorter function
const getDefaultColumnSorter = (column) => {
  return (a, b) => {
    const aValue = a[column.dataIndex] ?? "";
    const bValue = b[column.dataIndex] ?? "";

    // Default string comparison
    return collator.compare(aValue, bValue);
  };
};

const getDefaultColumnFilter = (column) => {
  return (filterValue, record) => {
    const cellValue = record[column.dataIndex];

    // Handle undefined or null values
    if (cellValue == null) {
      return filterValue === "";
    }

    // For numbers, do an exact match
    if (typeof cellValue === "number") {
      return cellValue === Number(filterValue);
    }

    // For strings, do a case-insensitive exact match
    const stringCellValue = String(cellValue).toLowerCase();
    const stringFilterValue = String(filterValue).toLowerCase();

    return stringCellValue === stringFilterValue;
  };
};

//TODO: adjust getColumnSorter in sorter story
export const getDefaultColumnConfig = (
  column,
  { filtersOptions, initialSort, initialFilters }
) => {
  const config = {
    ...column,
    render: (text) => <div style={{ minWidth: column.minWidth }}>{text}</div>,
  };

  // Apply sorter if not explicitly disabled
  if (column.sorter !== false) {
    config.sorter = getDefaultColumnSorter(column);
    config.sortOrder =
      initialSort.field === column.dataIndex ? initialSort.order : null;
  }

  // Apply filters if not explicitly disabled
  if (column.filters !== false) {
    config.filteredValue = initialFilters[column.dataIndex] || null;
    config.filters = filtersOptions[column.dataIndex] || [];
    config.filterSearch = true;
    config.onFilter = getDefaultColumnFilter(column);
  }

  return config;
};

/**
 * Extracts and formats the time from a datetime string.
 *
 * @param {string} datetime - The datetime string to extract the time from.
 * @returns {string} - The formatted time string.
 */
const formatTime = (datetime) => {
  return moment.parseZone(datetime).format("hh:mm A");
};

const formatDeliveryTime = (earlyDateTime, lateDateTime) => {
  const earliestDate = formatTime(earlyDateTime);
  const latestDate = formatTime(lateDateTime);
  return `${earliestDate} - ${latestDate}`;
};

//Determines the RP Row border color as per Delivery Status
export const getRPRowClassName = (record) => {
  return `table-row-${getRPDetailsColor(record.deliveryStatus)}`;
};

const getRPDetailsColor = (deliveryStatus) => {
  if (deliveryStatus !== undefined) {
    switch (deliveryStatus) {
      case "COMPLETED":
        return "green";
      case "EXCEPTION":
      case "MISSED":
      case "COMP_W_EXP":
        return "red";
      case "PENDING":
      case "ARRIVED":
      case "ENROUTE":
        return "blue";
      default:
        return "blue";
    }
  }
};

const formatDateTo12hTime = (date) => {
  return moment(date).format("hh:mm A");
};
const formatTimestamps = (estArrivalDateTime) => {
  const formatedDate = convertDateFormat(estArrivalDateTime.split(" ")[0]);
  const formatedTime = formatTo12HourRP(estArrivalDateTime.split(" ")[1]);
  return `${formatedDate} ${formatedTime}`;
};
